<template>
  <div class="auth-wrapper">
    <b-row class="auth-inner">
      <b-col sm="12" md="6" lg="3">
        <img width="274" src="@/assets/images/logo.svg"/>
        <h4 class="mt-5 forgot-title">{{ $t("forms.forgotPassword") }}</h4>
        <b-form class="auth-login-form" @submit.prevent="sendEmail">
          <b-form-group
            :class="{ row, error: v$.userEmail.$errors.length }"
            :label="$t('forms.email')"
            label-for="login-email"
          >
            <b-form-input id="login-email" type="email" v-model="userEmail"/>
            <div
              class="input-errors"
              v-for="error of v$.userEmail.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <div class="login-actions">
            <b-button type="submit" variant="primary" block>
              {{ $t("forms.forgotInstructions") }}
            </b-button>
            <router-link class="forgot-password" to="/login">{{ $t("forms.login") }}</router-link>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";

export default {
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      userEmail: '',
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      userEmail: {required, email},
    };
  },
  methods: {
    async sendEmail() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.$Users.createResourceByUrl({url: '/users/forgot_password', body: {email: this.userEmail}});
      }
    },
  },
}
</script>
